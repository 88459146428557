import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './TopNav.css';
import facebook from '../img/facebook.png';
import instagram from '../img/instagram.png';

const TopNav = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [english, setEnglish] = useState(false);

    useEffect(() => {
        if (location.pathname.startsWith('/en')) {
            setEnglish(true)
        }
    }, [location])

    const chooseLanguage = () => {
        if (english) {
            setEnglish(false);
            navigate('/')
        } else {
            setEnglish(true);
            navigate('/en');
        }
    }

    if (english) {
        return (
            <nav aria-label='main navigation' className='top-nav-box'>
                <div className="top-nav-socialmedia-box">
                    <a href="https://www.instagram.com/massotherapie.nedelya/?hl=en"><img className="top-nav-socialmedia-png pink-hover" src={instagram} alt='instagram link'/></a>
                    <a href='https://www.facebook.com/nedelya.massage'><img className="top-nav-socialmedia-png blue-hover"src={facebook} alt='facebook link'/></a>
                </div>
                <ul className="top-nav-links">
                    <li><Link to='en'>Home</Link></li>
                    <li><Link to='en/about'>About</Link></li>
                    <li><Link to='en/services'>Services</Link></li>
                    <li><Link to='en/shop'>Shop</Link></li>
                    <li><Link to='en/contact'>Reservation</Link></li>
                </ul>
                <div className="top-nav-button-box">
                    <button onClick={chooseLanguage} alt='choisir langue francaise' className='top-nav-button'>Fr</button>
                </div>
            </nav>
        )
    }

    return (
        <nav arial-label='main navigation' className='top-nav-box'>
            <div className="top-nav-socialmedia-box">
                <a href="https://www.instagram.com/massotherapie.nedelya/?hl=en"><img className="top-nav-socialmedia-png pink-hover" src={instagram} alt='instagram link'/></a>
                <a href='https://www.facebook.com/nedelya.massage'><img className="top-nav-socialmedia-png blue-hover" src={facebook} alt='facebook link'/></a>
            </div>
            <ul className="top-nav-links">
                <li><Link to=''>Accueil</Link></li>
                <li><Link to='fr/a-propos'>À Propos</Link></li>
                <li><Link to='fr/services'>Services</Link></li>
                <li><Link to='fr/boutique'>Boutique</Link></li>
                <li><Link to='fr/contact'>Réservation</Link></li>
            </ul>
            <div className="top-nav-button-box">
                <button onClick={chooseLanguage} className='top-nav-button' alt='choose english language'>En</button>
            </div>
        </nav>
    )
}

export default TopNav;